const organization_details = {
    page_title: '組織詳細資料',
    delete_confirmation: '刪除後，所有成員將失去他們在此組織的成員資格和角色。此操作無法撤銷。',
    organization_id: '組織 ID',
    /** UNTRANSLATED */
    settings_description: 'Organizations represent the teams, business customers, and partner companies that can access your applications.',
    name_placeholder: '組織的名稱，不需要是唯一的。',
    description_placeholder: '組織的描述。',
    member: '成員',
    member_other: '成員',
    add_members_to_organization: '將成員新增至組織 {{name}}',
    add_members_to_organization_description: '通過搜索姓名、電子郵件、電話或使用者 ID 尋找合適的使用者。現有成員不會顯示在搜尋結果中。',
    add_with_organization_role: '以組織角色添加',
    user: '使用者',
    authorize_to_roles: '授權 {{name}} 存取以下角色：',
    edit_organization_roles: '編輯組織角色',
    edit_organization_roles_of_user: '編輯 {{name}} 的組織角色',
    remove_user_from_organization: '從組織中移除使用者',
    remove_user_from_organization_description: '移除後，使用者將失去他們在此組織的成員資格和角色。此操作無法撤銷。',
    search_user_placeholder: '按姓名、電子郵件、電話或使用者 ID 搜尋',
    at_least_one_user: '至少需要一名使用者。',
};
export default Object.freeze(organization_details);
