const organization_details = {
    page_title: "Détails de l'organisation",
    delete_confirmation: 'Une fois supprimée, tous les membres perdront leur adhésion et leurs rôles dans cette organisation. Cette action ne peut pas être annulée.',
    organization_id: "Identifiant de l'organisation",
    /** UNTRANSLATED */
    settings_description: 'Organizations represent the teams, business customers, and partner companies that can access your applications.',
    name_placeholder: "Le nom de l'organisation, pas nécessairement unique.",
    description_placeholder: "Une description de l'organisation.",
    member: 'Membre',
    member_other: 'Membres',
    add_members_to_organization: "Ajouter des membres à l'organisation {{name}}",
    add_members_to_organization_description: "Trouvez les utilisateurs appropriés en recherchant leur nom, e-mail, téléphone ou identifiant d'utilisateur. Les membres existants ne sont pas affichés dans les résultats de la recherche.",
    add_with_organization_role: "Ajouter avec le(s) rôle(s) dans l'organisation",
    user: 'Utilisateur',
    authorize_to_roles: 'Autoriser {{name}} à accéder aux rôles suivants :',
    edit_organization_roles: "Modifier les rôles de l'organisation",
    edit_organization_roles_of_user: "Modifier les rôles de l'organisation de {{name}}",
    remove_user_from_organization: "Retirer l'utilisateur de l'organisation",
    remove_user_from_organization_description: "Une fois retiré, l'utilisateur perdra son adhésion et ses rôles dans cette organisation. Cette action ne peut pas être annulée.",
    search_user_placeholder: "Rechercher par nom, e-mail, téléphone ou identifiant d'utilisateur",
    at_least_one_user: 'Au moins un utilisateur est requis.',
};
export default Object.freeze(organization_details);
