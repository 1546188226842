const organization_details = {
    page_title: 'Детали организации',
    delete_confirmation: 'После удаления все участники потеряют свою членство и роли в данной организации. Это действие нельзя отменить.',
    organization_id: 'Идентификатор организации',
    /** UNTRANSLATED */
    settings_description: 'Organizations represent the teams, business customers, and partner companies that can access your applications.',
    name_placeholder: 'Название организации, не обязательно должно быть уникальным.',
    description_placeholder: 'Описание организации.',
    member: 'Участник',
    member_other: 'Участники',
    add_members_to_organization: 'Добавить участников в организацию {{name}}',
    add_members_to_organization_description: 'Найдите подходящих пользователей, выполнив поиск по имени, электронной почте, телефону или идентификатору пользователя. Существующие участники не отображаются в результатах поиска.',
    add_with_organization_role: 'Добавить с ролями организации',
    user: 'Пользователь',
    authorize_to_roles: 'Разрешить {{name}} доступ к следующим ролям:',
    edit_organization_roles: 'Редактирование ролей организации',
    edit_organization_roles_of_user: 'Редактирование ролей организации для {{name}}',
    remove_user_from_organization: 'Удалить пользователя из организации',
    remove_user_from_organization_description: 'После удаления пользователь потеряет свое членство и роли в этой организации. Это действие нельзя отменить.',
    search_user_placeholder: 'Поиск по имени, электронной почте, телефону или идентификатору пользователя',
    at_least_one_user: 'Необходимо указать хотя бы одного пользователя.',
};
export default Object.freeze(organization_details);
